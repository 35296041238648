import React, { useState, useEffect } from "react";
import { Container /* Row, Col */ } from "react-bootstrap";
import CardContainer from "../Grid/card_container";
import Mostplayed from "../Grid/most-played";
import Adventure from "../Grid/adventure";
// import RewardSpin from "../popups/spin";
import InterAd1 from "../Ads/nativead";
import CoinsShow from "../../Assets/Projects/coins_show_animation.gif"
import Rewardgift from "../popups/rewardgift";


if (localStorage.length === 0) {
  localStorage.setItem("coins", 0);
  localStorage.setItem("ads_reward", 0);
}

if(!localStorage.getItem('coins')){
  localStorage.setItem("coins", 0);
}

if(!localStorage.getItem('ads_reward')){
  localStorage.setItem("ads_reward", 0);
}

function Home() {
  const [showModal, setShowModal] = useState(false);
  let rewardads = Number(localStorage.getItem("ads_reward"));

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const [showElement, setShowElement] = useState(false);


  useEffect(() => {
    // Check if the user has visited the page before
    const visitedBefore = localStorage.getItem('visitedBefore');

    // If not visited before
    if (!visitedBefore) {
      setShowElement(true); // Show the element
      localStorage.setItem('visitedBefore', true); // Update visitedBefore flag
    }
    // Cleanup function
    return () => {
      setShowElement(false); // Show the element
      // Uncomment this line if you want to reset the visitedBefore flag when component unmounts
      // localStorage.removeItem('visitedBefore');
    };
  }, []); // 

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true); // Set showModal to true after 5 seconds
    }, 5000); // 5000 milliseconds = 5 seconds

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []); // Run this effect only once after component mount

const closeModal = () => {
  setShowModal(false);
};


  return (
    <section>
      <Container fluid className="" id="home">
        <Container className="home-content">
        <Rewardgift show={showModal} onHide={closeModal} />

          {/* <RewardSpin /> */}
          {/* <App/> */}
          {showElement && ( <img src={CoinsShow} alt="coinsshow" className="position-absolute img-fluid z-3" />)}

  
          <CardContainer />

          <div className="d-flex justify-content-center mb-2">
              <div style={{width:"100%", height:"300px"}}>
                    <ins className="adsbygoogle adslot_1"
                    style={{ display: "block"}}
                    data-ad-client="ca-pub-8026299207830445"
                    data-ad-slot="4327847264">
                </ins>
              </div>
            </div>
          <Mostplayed />
          <Adventure />
          <InterAd1/>
         
        </Container>
      </Container>
    </section>
  );
}

export default Home;
